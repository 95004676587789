import Vue from "vue";
import Vuex from "vuex";
import subscription from "./module/subscription"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showNotif: {},
  },
  mutations: {
    setshowNotif(state, showNotif) {
      state.showNotif = showNotif
    }
  },
  actions: {},
  modules: { subscription },
});
