export default {
  data() {
    return {
      registration: null,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.$swal({
        title: this.$t("we have updates! please refresh."),
        showClass: {
          popup: "swal2-show md-warning",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.refreshApp();
        }
      });
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    },
    createBasket() {
      const table = this.tableId
        ? { tableId: this.tableId, qrcode: 1 }
        : this.tableName
        ? { tableName: this.tableName, qrcode: 1 }
        : null;
      const basket = JSON.parse(localStorage.getItem("basket")) || [];
      const basketId = basket.find(
        (basket) => basket.restaurantId == this.restaurantId
      );
      if (!basketId) {
        return this.$store
          .dispatch("order/createBasket", {
            restaurantId: this.restaurantId,
          })
          .then(() => {
            if (table) {
              return this.$store
                .dispatch("order/setTable", {
                  restaurantId: this.restaurantId,
                  ...table,
                })
                .then((res) => {
                  let query = Object.assign({}, this.$route.query);
                  if (query.tableId) delete query.tableId;
                  else if (query.tableName) delete query.tableName;
                  this.$router.replace({ query });
                  if (res.type == "error") {
                    this.$swal({
                      title: this.$t(res.msg),
                      showCloseButton: true,
                      showConfirmButton: false,
                      showClass: {
                        popup: "swal2-show md-warning",
                      },
                    });
                  }
                });
            }
          });
      } else {
        if (table) {
          return this.$store
            .dispatch("order/setTable", {
              restaurantId: this.restaurantId,
              ...table,
            })
            .then((res) => {
              if (res.type == "error") {
                this.$swal({
                  title: this.$t(res.msg),
                  showCloseButton: true,
                  showConfirmButton: false,
                  showClass: {
                    popup: "swal2-show md-warning",
                  },
                });
              }
              // this.addNewTime().then(() => {
              let query = Object.assign({}, this.$route.query);
              if (query.tableId) delete query.tableId;
              else if (query.tableName) delete query.tableName;
              this.$router.replace({ query });
              // });
            });
        }
      }
    },
  },
};
