 function auth({ next, store }) {
  if (!store.state.orderiomApiPackage.auth.privateToken && !localStorage.privateToken) {
    return next({
      name: "Login",
    });
  }
  return next();
}
function login({ next, store }) {
  if (store.state.orderiomApiPackage.auth.privateToken || localStorage.privateToken) {
    return next({
      name: "Home",
    });
  }
  return next();
}
export {
  auth,
  login
}