<template>
  <div>
    <router-view />
    <Notification
      :show="showNotif.show"
      :msg="showNotif.msg"
      :type="showNotif.type"
    />

    <a
      class="go-up hidden md:inline-flex"
      :class="[scrollTop ? 'show' : 'hide']"
      href="#"
      id="js-top"
      aria-label="Go to top"
    >
      <i class="fa fa-angle-up"></i>
    </a>
    <loading
      :active.sync="isLoading"
      color="#f0e3cc"
      loader="bars"
    ></loading>
  </div>
</template>
<script>
import axios from "axios";
import mixin from "./mixin/update.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  mixins: [mixin],
  data() {
    return {
      scrollTop: false,
      isLoading: false,
    };
  },
  computed: {
    showNotif() {
      return this.$store.state.showNotif;
    },
  },
  components: {
    Notification: () => import("@/components/Notification.vue"),
    Loading,
  },
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.isLoading = true;
          return config;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.isLoading = false;
          return response;
        },
        (error) => {
          this.isLoading = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.validation
          ) {
            if (
              Object.keys(error.response.data.error.validation).includes(
                "restaurantId"
              ) &&
              error.response.data.error.validation["restaurantId"][0] ==
                "basket_does_not_exists"
            ) {
              localStorage.removeItem("basket");
              if (this.$route.path == "/menu") {
                this.createBasket();
                return;
              }
              this.$router.push("/");
            }
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.body == "unauthenticated" &&
            localStorage.getItem("privateToken")
          ) {
            localStorage.clear();
            window.location.reload();
          }
          return Promise.reject(error);
        }
      );
    },
    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor);
    },
    scrollFunc() {
      let y = window.scrollY;

      if (y > 600) {
        this.scrollTop = true;
      }
      if (y < 600) {
        this.scrollTop = false;
      }
    },
  },
  created() {
    this.enableInterceptor();
    window.addEventListener("scroll", this.scrollFunc);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunc);
  },
  mounted() {
    this.$store.dispatch("auth/auth");
  },
};
</script>
<style>
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/style.css";
/* @import "./assets/css/home.css"; */
</style>
