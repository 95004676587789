import Vue from "vue";
import VueRouter from "vue-router";
import {auth,login} from "./middleware/auth";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Home",
    // redirect: '/dashboard',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login",
      middleware: [login],

    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      title: "Register",
      middleware: [login],

    },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      title: "ResetPassword",
    },
  },
  {
    path: "/dashboard",
    component: () => import("../components/Dashboard.vue"),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../views/start.vue"),
        meta: {
          title: "Dashboard",
          middleware: [auth],

        },
      },
      {
        path: "anlegen",
        name: "Anlegen",
        component: () => import("../views/Anlegen.vue"),
        meta: {
          title: "Anlegen", middleware: [auth],

        },
      },
      {
        path: "zusatzbestellung",
        name: "Zusatzbestellung",
        component: () => import("../views/Zusatzbestellung.vue"),
        meta: {
          title: "Zusatzbestellung", middleware: [auth],

        },
      },
      {
        path: "unterbrechen",
        name: "Unterbrechen",
        component: () => import("../views/Unterbrechen.vue"),
        meta: {
          title: "Unterbrechen", middleware: [auth],

        },
      },
      {
        path: "ansehen-anderen",
        name: "AnsehenAnderen",
        component: () => import("../views/AnsehenAnderen.vue"),
        meta: {
          title: "Ansehen-Anderen", middleware: [auth],

        },
      },
      {
        path: "preview",
        name: "Preview",
        component: () => import("../views/Preview.vue"),
        meta: {
          title: "Preview", middleware: [auth],

        },
      },
      {
        path: "cart",
        name: "Cart",
        component: () => import("../views/Cart.vue"),
        meta: {
          title: "Review Card",
        },
      },
      {
        path: "checkout",
        name: "Checkout",
        component: () => import("../views/Checkout.vue"),
        meta: {
          title: "Palce Order", middleware: [auth],

        },
      },
    ],
  },
  {
    path: "/paymentstatus",
    name: "PaymentStatus",
    component: () => import("../views/PaymentStatus.vue"),
    meta: {
      title: "Payment status",
    },
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("../views/Blogs.vue"),
    meta: {
      title: "Von den Blogs",
    },
  },
  {
    path: "/blogs/:id",
    name: "Blog",
    component: () => import("../views/BlogFull.vue"),
    meta: {
      title: "Blog",
      sitemap: {
        slugs: [
          "blogId",
          // Slugs can have their own meta tags
          {
            token: "blog detail"
          }
        ],
        "ignoreRoute": true
      }
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/MainAbout.vue"),
    meta: {
      title: "Über uns",
    },
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
    meta: {
      title: "Unsere Dienstleistungen ",
    },
  },
  {
    path: "/resetpass/:token",
    name: "ResetPass",
    component: () => import("../views/ResetPass.vue"),
    meta: {
      title: "Reset your password",
      sitemap: {
        slugs: [
          "reset-password",
          {
            token: "your-own-token",
          },
        ],
        ignoreRoute: true,
      },
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export { router, routes };
