import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { router } from "./router";
import store from "./store";
import orderiomApi from "orderiom-api-package";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import VueGtag from "vue-gtag";
import VWave from 'v-wave'
import shareFunction from "./mixin/shareFunction"
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/css/tailwind.css";
import "animate.css";
import i18n from "./i18n";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
axios.interceptors.request.use((config) => {
  let publicToken = localStorage.getItem("publicToken");
  let privateToken = localStorage.getItem("privateToken");
  if (publicToken) {
    config.headers["Authorization"] = `Bearer ${publicToken}`;
  }
  if (privateToken) {
    config.headers["Authorization"] = `Bearer ${privateToken}`;
  }
  config.headers["Accept"] = "aplication/json";

  return config;
});

Vue.use(VueSweetalert2, {
  confirmButtonColor: process.env.VUE_APP_PRIMARY_COLOR,
});
Vue.config.productionTip = false;
Vue.use(orderiomApi, { store });
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_RESTAURANT_GTAG }
});
Vue.use(VWave)
Vue.mixin(shareFunction)
router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle && nearestWithTitle.meta.title)
    document.title =
      process.env.VUE_APP_RESTAURANT_NAME + " | " +
      i18n.t(nearestWithTitle.meta.title);

  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  if (!nearestWithMeta) {
    if (!to.meta.middleware) {
      return next();
    }
    return middleware[0]({
      ...context,
    });
  }

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  if (!to.meta.middleware) {
    next();
  }
  middleware[0]({
    ...context,
  });
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
