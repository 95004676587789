export default {
    data() {
        return {
            completeweekdaysList: [
                { id: 1, name: "Mo", completeName: 'monday' },
                { id: 2, name: "Di", completeName: 'tuesday' },
                { id: 3, name: "Mi", completeName: 'wednesday' },
                { id: 4, name: "Do", completeName: 'thursday' },
                { id: 5, name: "Fr", completeName: 'friday' },
                { id: 6, name: "Sa", completeName: 'saturday' },
                { id: 7, name: "So", completeName: 'sunday' },
            ],
        }
    },
    computed: {
        weekdaysList() {
            if (this.$store.state.orderiomApiPackage.restaurant.restaurantInfo && this.$store.state.orderiomApiPackage.restaurant.restaurantInfo.restaurantAvailableTimes) {
                const restaurantAvailableTimes = this.$store.state.orderiomApiPackage.restaurant.restaurantInfo.restaurantAvailableTimes.map(m => m.weekday) || []
                return this.completeweekdaysList.filter(f => restaurantAvailableTimes.includes(f.completeName))
            }
            return []
        }
    },
    methods: {
        formatDate(date) {
            date = new Date(date);
            var monthNames = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "June",
                "July",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];

            var day = date.getUTCDate();
            var monthIndex = date.getUTCMonth();
            var year = date.getFullYear();
            return monthNames[monthIndex] + '.' + day + '.' + year;
        },
        qtyPerDay(id, pattern) {
            return pattern.find((item) => item.weekdayId === id)
                ? pattern.find((item) => item.weekdayId === id).quantity
                : 0;
        },
        sumOfPerCategory(products) {
            if (products && products.length)
                return products.reduce((a, b) => a + b.quantity, 0)
            return 0
        }
    }
}