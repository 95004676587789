import axios from "axios"

const state = {
    pattern: [],
    breakList: [],
    nextPattern: null,
    restaurantId: null,
    userSammary: null,
    userActivity: null
}
const mutations = {
    setPattern(state, pattern) {
        state.pattern = pattern
    },
    setBreakList(state, breakList) {
        state.breakList = breakList
    },
    setNextPattern(state, nextPattern) {
        state.nextPattern = nextPattern
    },
    setRestaurantId(state, restaurantId) {
        state.restaurantId = restaurantId
    },
    setUserSummary(state, userSammary) {
        state.userSammary = userSammary
    },
    setUserActivity(state, userActivity) {
        state.userActivity = userActivity
    }
}
const actions = {
    getUserOriginId({ commit }, data) {
        return axios.get('api/auth/get-user-restaurant-by-email', { params: data }).then((res) => {
            commit('setRestaurantId', res.data.data.origin_restaurant);
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    createPattern({ dispatch }, data) {
        return axios.post('api/subscribe/create-pattern', data).then(() => {
            dispatch('getPattern')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    getPattern({ commit }) {
        return axios.get('api/subscribe/get-pattern-by-user-id').then((res) => {
            commit('setPattern', res.data.data)
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    updatePattern({ dispatch }, data) {
        return axios.post('api/subscribe/update-pattern-product', data).then(() => {
            dispatch('getPattern')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    deleteProductFromPattern({ dispatch }, data) {
        return axios.get('api/subscribe/delete-pattern-product', { params: data }).then(() => {
            dispatch('getPattern')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    deletePattern({ dispatch }, id) {
        return axios.post('api/subscribe/destroy-pattern', null, { params: { pattern_id: id } }).then(() => {
            dispatch('getPattern')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    createBreak({ dispatch }, data) {
        return axios.post('api/subscribe/create-break', null, { params: data }).then(() => {
            dispatch('getBreak')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    deleteBreak({ dispatch }, id) {
        return axios.post('api/subscribe/destroy-break', null, { params: { break_pattern_id: id } }).then(() => {
            dispatch('getBreak')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    updateBreak({ dispatch }, data) {
        return axios.post('api/subscribe/update-break', null, { params: data }).then(() => {
            dispatch('getBreak')
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    getBreak({ commit }) {
        return axios.get('api/subscribe/get-break').then((res) => {
            commit('setBreakList', res.data.data)
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            console.clear()
        });
    },
    getNextPattern({ commit }) {
        axios.get('api/subscribe/get-next-pattern-products-by-user').then((res) => {
            commit('setNextPattern', res.data.data)
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            // console.clear()
        });
    },
    getUserSummary({ commit }) {
        return axios.get('api/subscribe/get-percentage-summary').then((res) => {
            commit('setUserSummary', res.data.data.percentage)
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            // console.clear()
        });
    },
    getUserActivity({ commit }) {
        return axios.get('api/subscribe/get-user-activity').then((res) => {
            commit('setUserActivity', res.data.data.percentage)
            return {
                type: 'success',
                msg: 'ok'
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    return {
                        type: 'error',
                        msg: Object.values(error.response.data.error.validation).map(m => { return m[0] }).toString()

                    }
                }
                if (error.response.status == 401 || error.response.status == 403 || error.response.status == 404 || error.response.status == 400) {
                    return {
                        type: 'error',
                        msg: error.response.data.message.body

                    }
                }

            }
            // console.clear()
        });
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
};